$gapBetweenThumbnails: 5px;

/* mobile/tablet */
@media only screen and (max-width: 899px) {
    .img-thumbnail {
        // arrange thumbnails in rows of 2. If image is too wide, there will only be 1 per row
        width: calc(50% - #{$gapBetweenThumbnails} - 10px);
    }
}

/* desktop */
@media only screen and (min-width: 900px) {
    .img-thumbnail {
        // arrange thumbnails in rows of 3. If image is too wide, there will only be 2 per row
        width: calc(33% - #{$gapBetweenThumbnails} - 5px);
    }
}

.thumbnail-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: $gapBetweenThumbnails;
    width: 100%;
}

.img-thumbnail {
    aspect-ratio: 1 / 1;
    object-fit: cover;
    cursor: pointer;
}

.photo-album-title {
    padding-right: 20px;
    font-size: 2.5em;
    margin-bottom: 5px;
    text-align: right;
    font-weight: lighter;
    letter-spacing: 3px;
    text-transform: lowercase;
}